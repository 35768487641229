<template>
  <v-col cols="6" sm="6" md="6" class="ml-6 d-none d-sm-flex">
    <v-text-field
        @keyup.native.enter="goToSearchPage"
        flat
        hide-details
        solo
        v-model="search"
        clearable
        background-color="grey lighten-2"
        class="body-2 align-center"
        placeholder="Search.."
        prepend-inner-icon="mdi-magnify"
    >
    </v-text-field>
  </v-col>
</template>
<script>
export default {
  name: "GlobalSearch",
  data() {
    return {
      search: ''
    };
  },
  methods: {
    goToSearchPage() {
     this.$router.push({name: 'search',params: {search: this.search}})
    }
  }
};

</script>
